@mixin alpha($property, $color-variable, $opacity) {
    $color-variable-h: var(#{$color-variable+'-h'});
    $color-variable-s: var(#{$color-variable+'-s'});
    $color-variable-l: var(#{$color-variable+'-l'});
    #{$property}: hsla($color-variable-h, $color-variable-s, $color-variable-l, $opacity);
}

@mixin defineColorHSL($color, $hue, $saturation, $lightness){
    #{$color}: unquote("hsl(#{$hue}, #{$saturation}, #{$lightness})");#{$color}-h: #{$hue};#{$color}-s: #{$saturation};#{$color}-l: #{$lightness};
}

@mixin btn_padding($padding-base: 1, $coef: 0.15rem) {
    padding: multiply( addition($padding-base, 1), $coef) multiply( addition($padding-base, 3), $coef);
}

@mixin carre($size) {
    width: $size;
    height: $size;
}

@mixin background($color) {

    @if $color == 'primaire' {        
        background-color: $primaire-500;
    }

    @if $color == 'secondaire' {        
        background-color: $secondaire-500;
    }
}

@mixin wrapper{

    .container {
        width: 100%;
    }
    @each $size in $sizes {
        @media (min-width: $size) {
            max-width: $size;
        }
    }
    
    margin-left: auto;
    margin-right: auto;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
}

@mixin wrapper-2{

    @each $size in $sizes {
        @media (min-width: math.div($size, 2)) {
            max-width: math.div($size, 2);
        }
    }
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
}
