/* GridImages
   ========================================================================== */

.gridImages{
    display: grid;
    
    &__images{
        max-height: 260px;
        min-height: 170px; 
        
        @media screen and (min-width: 769px){ 
            max-height: 440px;
        }
    }

    @media screen and (min-width: 640px){ 
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    }
}