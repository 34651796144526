/* Dropdown
   ========================================================================== */

.dropdown{

    @media screen and (max-width: 767px){ 
        flex-direction: column;
        align-items: start;
    }


    &::before{
        display: none;
    }

    // &__button{
    //     padding-left: 23px; 
    // }
    &__wrapper{
        overflow: hidden;
        position: relative;
        width: 100%;

        @media screen and (min-width: 768px){ 
            position: absolute;
            bottom: 0;
            transform: translateY(calc(100% + 17px));
            width: max-content;
            max-width: 220px; 
        }
    }
    &__list{
        position: absolute;
        bottom: 0;
        transform: translateY(0%);
        background: white;
        overflow: hidden;
        padding-left: 3rem;
        padding-right: 1.2rem;
        z-index: -50;
    }

    &__item{
        list-style-position: outside;

        .link{
            color: $tertiaire-500;
        }
    }

    &--open{
        .dropdown{
            &__list{
                position: relative;
                @extend .py-1;
                z-index: 0;
                
            }
        }
    }
}