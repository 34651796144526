&__burger{
    position: relative;
    width: 24px;
    height: 20px;
    

    span{
        // background: $tertiaire-500;
        background: white;
        height: 5px;
        display: block;
        width: 100%;
        margin-left: auto;
        transition: all .3s ease-in;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 1rem;

        &:nth-child(2){
            width: 1.2rem;
            top: 50%;
            transform: translateY(-50%);
            margin: auto;
            
        }

        &:nth-child(3){
            width: 1rem;
            top: initial;
            bottom: 0;
        }
    }

    &:hover{
        span{
            width: 1.5rem;
        }
    }

    &--open{
        span{
            &:nth-child(1){
                top: 50%;
                transform: translateY(-50%) rotate(45deg);
            }
            &:nth-child(2){
                width: 0%;
                left: 0;
            }
            &:nth-child(3){
                top: initial;
                bottom: 50%;
                transform: translateY(50%) rotate(-45deg);
                width: 100%;
            }
        }
    }


    @media screen and (min-width: 768px){ 
        display: none; 
        visibility: hidden;
    }
}