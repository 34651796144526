/* Breadcrumbs
   ========================================================================== */

.breadcrumbs{
    margin-top: 1rem;
}

.breadcrumbs__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    text-transform: uppercase;
    font-family: $font-2;
    font-size: 1.2rem;

    & :first-child{
        padding-left: 0;   
    }
}

.breadcrumbs__items {
    list-style: none;
    padding: .5rem 1rem;

    &:last-child{
        color: $primaire-500;
    }
}

.breadcrumbs__links.link{
    color: white;
}

.breadcrumbs .link{
    margin-top: 1rem;
}