/* Links
   ========================================================================== */

.link{
    color: $primaire-500;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-image: linear-gradient(to right, $secondaire-700, $secondaire-700 50%, currentColor 50%);
    background-position: 100%;
    text-decoration: none;


    &:hover{
        transition: all 0.4s ease-in-out;
        background-position: 0%;
        
    }

    &--dark{
        color: $secondaire-500;
        background-image: linear-gradient(to right, $primaire-700, $primaire-700 50%, currentColor 50%);
    }

    &--light{
        color: $secondaire-500;
        background-image: linear-gradient(to right, $primaire-500, $primaire-500 50%, currentColor 50%);
    }

    &--social{
        display: inline-flex;
        height: 70px;
        width: 30px;
        justify-content: center;
        align-items: center;
    }

}

.link.link--social {
    &:hover,
    &:focus{
        background-image: linear-gradient(to right, white, white 50%, currentColor 50%);
    }

}