
/* Paginations
   ========================================================================== */

.pagination{
    font-family: $font-2;

    &__list{
        display: flex;
        gap: .5rem;
        width: max-content;
    }

    &__item{
        list-style: none;
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;

        &:first-child .pagination__link,
        &:last-child .pagination__link{
            color: $tertiaire-500;
        }
    }

    &__select{
        border-bottom: 2px solid $tertiaire-500;

        .pagination{
            &__link{
                color: $tertiaire-500;
            }
        }
    }

    &__link{
        color: $primaire-500;
    }
}