/* Grid
   ========================================================================== */



$alignItems: (
    'start':     'flex-start',
    'end':       'flex-end',
    'center':    'center',
    'baseline':  'baseline',
    'stretch':   'stretch',
);

$justifyContent: (
    'start':     'flex-start',
    'end':       'flex-end',
    'center':    'center',
    'between':   'space-between',
    'around':    'space-around',
    'evenly':    'space-evenly',
);

@each $name, $value in $alignItems {
    .align-items-#{$name}{
        align-items: #{$value} ;
    }
}

@each $name, $value in $justifyContent {
    .justify-content-#{$name}{
        justify-content: #{$value} ;
    }
}

    .grid-flex{
        display: flex;
        justify-content: space-between;
        gap: 2rem;
        flex-wrap: wrap;
        align-items: center;

        .col{
            flex: 1 1 0%;
            width: 100%;
            min-width: 100%;

            @media screen and (min-width: 640px){ 
                min-width: 350px;
            }

            &.min-sm{
                @media screen and (max-width: 1023px){ 
                    min-width: 100%;
                }
            }

            &--x4{
                @extend .col;
                flex:4
            }
        }

        &--no-wrap{
            @extend .grid-flex;

            @media screen and (min-width: 768px){ 
                flex-wrap: nowrap;
                gap: 1rem;
                align-items: flex-start;
            }

        }

        
    }

    .grid{
        display: grid;
        

        @for $i from 1 to 12 {
            &-template-colums-#{$i}{
                @extend .grid;
                grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
            }
        }

        &--align-start{
            align-items: start;
        }
    }


.blocTextTable .grid-flex .col{
    @media screen and (min-width: 640px){ 
        min-width: 550px;
    }
}

[class*="imageCol"]{

    .grid-flex{
        align-items: stretch;
    }

    .col{
        display: flex;
    }
}


