/* Overlay
   ========================================================================== */


.overlay{
    color: white;
    position: relative;
    
    &__image{
        @extend .images--full;
        position: absolute;
    }

    &__block{
        display: block;
        width: 100%;
        height: 100%;
        @extend .bg-color3-500;
        opacity: .6;
        position: absolute;

        & ~ *{
            position: relative;
            z-index: 10;
        }
    }

    &__content{
        position: relative;
        z-index: 5;
    }
}