/* Slider
   ========================================================================== */
.slider{

    .splide{
        display: flex;
        align-items: center;
        gap: 1rem;

        &__arrows {
            position: absolute;
            display: flex;
            width: 100%;
            max-width: max-content;
            transform: translateY(100%);
            height: auto;
            bottom: -.7rem;
            left: 3rem;
            font-size: 1.5rem;
            gap: 1rem;
        }

        &__arrow {
            position: relative;
            top: 0;
            left: 0;
            opacity: 1;
            transform: none;
            background-color: transparent;
            height: auto;
            width: initial;
            color: var(--color2-500);
        }

        &__pagination {
            position: absolute;
            right: initial;
            left: -1.5rem;
            
            li{
                display: flex;
                padding: .15rem 0;
                
                button{
                    color: var(--color2-500);
                }
            }

            &__page{
                background: currentColor;
                opacity: 1;

                &.is-active{
                    background: $primaire-500;
                }
            }
        }

        &__link{
            @extend .link;
            color: white;
        }

        &__slide{
            list-style-image:none;
            padding: 0;
            background-color: $tertiaire-500;
            overflow: hidden;
            height: max-content;
            position: relative;

            &:nth-child(2n){
                @extend .radius--right-top-med;
                
            }

            &:nth-child(2n+1){
                @extend .radius--right-bottom-med;
            }

            .card__image{
                height: 230px;
            }

            .card{
                p, h3{
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

                h3{
                    -webkit-line-clamp: 2;
                }
                
                p{
                    -webkit-line-clamp: 3;
                    padding: 0;
                }
            }

            .icofont{
                color: $primaire-500;
            }

            &.is-active{
                transition: all .5s ease-in-out;
                @extend .bg-color1-500;
                color: var(--color1-500);

                .icofont{
                    transition: all .5s ease-in-out;
                    color: white;
                }

                .card p{
                    -webkit-line-clamp: 6;
                }

                .splide__link{
                    @extend .link--dark;
                }
            }
        }
    }

    .link{
        -webkit-text-fill-color: initial
    }

    &--header{
        .splide__slide .card__content p{
            height: 0;
        }

        .splide__slide.is-active .card__content p{
            -webkit-line-clamp: 4;
            height: auto;
        }

        .splide__slide{
            background-color: color.adjust(map-get($map: $colors, $key: tertiaire), $blackness: 5%, $alpha: -0.2);
        }

        .splide__pagination__page{
            background-color: white;
        }
    }

    
}

.slider--absolute{

    .slider{
        width: 100%;
    }

    @media screen and (min-width: 768px){ 
        flex-wrap: nowrap;


        
        .col{
            min-width: 400px;
        }
    }
    
    
    .splide__track{
        
    }
}

.splide__slide.is-visible .splide__link{
    -webkit-text-fill-color: initial !important;
    .card__content,
    .card__content h3{
        color: white;
    }

    [class*=" icon-"]{
        justify-content: start;
    }
}

.splide__slide.is-active.is-visible .splide__link{
    -webkit-text-fill-color: initial !important;
    .card__content,
    .card__content h3{
        @extend .text-color2-500;
    }

    [class*=" icon-"]{
        color: white;
    }
}
