&__slide{

    &--right{
        transition: transform .5s ease-in-out;
        &.hide {
            transform: translateX((100px + 5px) * 2) ;
        }
    }

    &--bottom{
        transition: transform .5s ease-in-out;
        &.hide {
            transform: translateY(( 100px + 5px ) * 2) ;
        }
    }
}