/* Backtop
   ========================================================================== */

.backTop {
    display: flex;
    visibility: visible;
    position: fixed;
    bottom: 50px;
    right: 50px;
    cursor: pointer;
    padding: 5px;
    width: 50px;
    height: 50px;
    background-color: $tertiaire-500;
    color: white;
    text-decoration: none;
    display: flex;
    justify-content:center;
    align-items: center;
    border-radius: 0;
    z-index: 9999;
    box-shadow: 2px 2px 10px #0000005e;

    @media screen and (max-width: 639px){ 
        bottom: 20px;
        right: 20px;
    }

    .icofont::before{
        color: white;
    }

    &:hover {
        background-color: $primaire-500;
    }

    // OPTIONS //
    &--rounded{
        border-radius: 50%;
    }

    &--border{
        border: 1px solid $secondaire-500;
    }

    @import 'backTop_slide';
    @import 'backTop_fade';
    @import 'backTop_zoom';

}



