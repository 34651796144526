// .icon {
//     display: flex;
//     align-items: center;

// 	&::before{
// 		display: flex;
// 		align-items: center;
// 	}
// }

[class^="icon-"], 
[class*=" icon-"] {
    display: flex;
    align-items: center;
    justify-content: center;
}

[class^="icon-"]:before, 
[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}