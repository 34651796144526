/* Calendar
   ========================================================================== */


#calendar{

    tr{
        height: 25px;
    }
    tbody .fc-scrollgrid-section:not(:last-child){
            display: none;
    }
    

    .fc-today-button,
    .fc-button-group,
    .fc-toolbar,
    thead{
        display: none;
    }

    .fc-v-event .fc-event-title-container{
        font-size: .7rem;
    }

    .fc-v-event .fc-event-main-frame{
        padding: 3px;
    }

    .fc-view-harness {
        overflow-x: scroll;

        @media screen and (min-width: 1024px){ 
            overflow-x: initial;
        }

        .fc-timegrid {
            min-width: 850px;
        }
    }

}