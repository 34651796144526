/* Titles
   ========================================================================== */

h1, h2, h3, h4, h5, h6, .subTitle{
    font-family: $font-2;
    font-weight: 500;
    line-height: 1.4em;
    margin: 0;
}

h1{
    font-size: clamp(300%, 10vw, 300%);
    text-transform: uppercase;
}

h2{
    font-size: clamp(250%, 10vw, 250%);
}

h3{

    font-size: clamp(200%, 10vw, 200%);
}

h4{
    font-size: clamp(170%, 10vw, 190%);
}

h5{
    font-size: clamp(130%, 10vw, 160%);
}

h6{
    font-size: clamp(120%, 10vw, 140%);
}



.subTitle{
    font-size: 1.5rem;
    text-transform: uppercase;
    margin: 0;
}

.title--ico{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
}

.align-center{
    .title--ico{
        align-items: center;
        flex-direction: column;
    }
}

.align-right{
    .title--ico{
        justify-content: flex-end;
    }
}