/* Article
   ========================================================================== */

.article{

    .date{
        display: flex;
        gap: .5rem;
        align-items: center;
    }
}