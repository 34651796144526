&__list{
    display: flex;
    gap: clamp(.5rem,50% , 2vw);
    flex-wrap: wrap;
    flex-direction: column;
    padding: 3vw clamp( 2rem , 50% ,10vw);
    font-family: $font-2;
    font-size: 1rem;
    font-weight: 400;

    @media screen and (min-width: 768px){ 
        padding: 0;
        flex-direction: row;
        justify-content: end;

        &:first-child{
            flex:1;
        }
    } 

    .menu__item{
        margin-top: 0.1rem;
        margin-bottom: 0.1rem;
    }

    &.icones{
        display: flex;
        gap: .2rem;

        // .link{
        //     color: $primaire-700;

        //     &:hover{
        //         color: white;
        //     }
        // }


        .menu__item{
            padding: 0;

            .link{
                margin-top: -1.5rem;

                .icon:before{
                    transform: translateY(-.6rem);
                }
            }
        }

        li{
            margin: 0;
            padding: 0;
        }

        @media screen and (min-width: 768px){ 
            flex-wrap: nowrap;
            padding-left: .9rem;

            .menu__item .link .icon:before{
                transform: translateY(0rem);
            }
        }

        @media screen and (max-width: 767px){ 
            .menu__item--connect{
                display: none;
                visibility: hidden;
            }
        }
    }
}

.menu__list.menu__list--mobile{
    margin-left: auto;
    margin-right: 0;
    padding: 1rem;
    .menu__item .link .icon:before{
        color: white;
        transform: translateY(0);
    }
}

@media screen and (min-width: 768px){ 
    .menu__list.menu__list--mobile{
        display: none;
        visibility: hidden;
    }
}


&__item{
    list-style: none;
    display: flex;
    gap: .5rem;
    align-items: center;

    &:before{
        content: '';
        display: none;
        width: 0;
        height: 15px;
        mask: url(./../images/svg/puce.svg);
        mask-repeat: repeat;
        background: transparent;
        mask-position: center;
        mask-repeat: no-repeat;
        transition: all .3s ease-in-out;

        @media screen and (max-width: 767px){ 
            width: 15px;
        }
    }

    &.active{
        &:before{
            display: block;
            width: 15px;
            background: $primaire-700;
        }
    }

    .link{
        background-image: none;
        -webkit-text-fill-color: initial;

        &:hover{
            color: $primaire-700;
        }

        @media screen and (min-width: 768px){ 
            color: white; 
        }
    }
}