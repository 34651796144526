/* Table
   ========================================================================== */
.table{

    display: block;
    font-family: $font-2;

    .tbody, .thead{
        width: 100%;
        display: block;
    }

    .td, .th{
        flex:1;
        @extend .align-center;
    }

    .tr, .tr{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-around;
    }

    .tr{
        color: $primaire-500;
        text-transform: uppercase;
        overflow: hidden;
    }

    .tbody .tr{
        background: #F5F5F5;
    }

    .thead .tr,
    .tbody .td:before{
        color: $tertiaire-500;
        @extend .font-normal; 
        @extend .text-xl

    }

    a{
        max-width: initial;
        @extend .radius--right-bottom;
        text-align: center;
    }

    @media screen and (max-width: 767px){ 

        .thead {
            display: none;  
        }

        .tr{
            display: block;
            margin-bottom: 40px;
        }

        .td {
            display: flex;
            text-align: right;
            justify-content: space-between;

            &::before{
                content: attr(data-label);
                float: left;
                font-weight: bold;
                text-align: left;

                @media screen and (max-width: 639px){ 
                    max-width: 250px;
                }
            }

            [class*="button-"]{
                flex: 1;
            }
        }
    
    }

}


