// Colors
$colors: (
    primaire: #A4A864,
    secondaire: #292B35,
    tertiaire: #093B51,
)!default;

// @each $name, $hex in $colors {

//     $name: map-get($map: $colors, $key: #{$name});    

// }
$primaire-500: map-get($map: $colors, $key: primaire) !default;
$secondaire-500:  map-get($map: $colors, $key: secondaire) !default;
$tertiaire-500:  map-get($map: $colors, $key: tertiaire) !default;

$colorsVariantes: (
    color1:(
        100: tint($primaire-500, 80%),
        200: tint($primaire-500, 60%),
        300: tint($primaire-500, 40%),
        400: tint($primaire-500, 20%),
        500: $primaire-500,
        600: shade($primaire-500, 20%),
        700: shade($primaire-500, 40%),
        800: shade($primaire-500, 60%),
        900: shade($primaire-500, 80%),
    ),
    color2:(
        100: tint($secondaire-500, 80%),
        200: tint($secondaire-500, 60%),
        300: tint($secondaire-500, 40%),
        400: tint($secondaire-500, 20%),
        500: $secondaire-500,
        600: shade($secondaire-500, 20%),
        700: shade($secondaire-500, 40%),
        800: shade($secondaire-500, 60%),
        900: shade($secondaire-500, 80%),
    ),
    color3:(
        100: tint($tertiaire-500, 80%),
        200: tint($tertiaire-500, 60%),
        300: tint($tertiaire-500, 40%),
        400: tint($tertiaire-500, 20%),
        500: $tertiaire-500,
        600: shade($tertiaire-500, 20%),
        700: shade($tertiaire-500, 40%),
        800: shade($tertiaire-500, 60%),
        900: shade($tertiaire-500, 80%),
    ),
) !default;

$primaire-100 : var(--color1-100);
$primaire-200 : var(--color1-200);
$primaire-300 : var(--color1-300);
$primaire-400 : var(--color1-400);
$primaire-500 : var(--color1-500);
$primaire-600 : var(--color1-600);
$primaire-700 : var(--color1-700);
$primaire-800 : var(--color1-800);
$primaire-900 : var(--color1-900);

$secondaire-100 : var(--color2-100);
$secondaire-200 : var(--color2-200);
$secondaire-300 : var(--color2-300);
$secondaire-400 : var(--color2-400);
$secondaire-500 : var(--color2-500);
$secondaire-600 : var(--color2-600);
$secondaire-700 : var(--color2-700);
$secondaire-800 : var(--color2-800);
$secondaire-900 : var(--color2-900);

$tertiaire-100 : var(--color3-100);
$tertiaire-200 : var(--color3-200);
$tertiaire-300 : var(--color3-300);
$tertiaire-400 : var(--color3-400);
$tertiaire-500 : var(--color3-500);
$tertiaire-600 : var(--color3-600);
$tertiaire-700 : var(--color3-700);
$tertiaire-800 : var(--color3-800);
$tertiaire-900 : var(--color3-900);

// Fonts
$fontsVariantes: (
    1: " 'Open Sans', sans-serif",
    2: " 'Mulish', sans-serif ",
) !default;

$sizes: (
    640px, 
    768px, 
    1024px, 
    1280px,
    1536px,
)!default;

// $radius: 0 !default;
// $padding-base: 2rem !default;
// $grid-gap-x: 2rem !default;
// $grid-gap-y: 2rem !default;
// $bg-menu: white !default;
// $sizeMenu: 80px !default;

// Borders
// $border-size: .3rem !default;
// $border-color: $primaire-500 !default;
// $border-style: solid !default;

// Fonts
$font-1: var(--font-family1) !default;
$font-2: var(--font-family2) !default;
$font-base: 16px !default;
// $font-weight: 500 !default;



// Links
// $links-color: $secondaire-500 !default;
// $links-color-hover: $primaire-500 !default;
// $links-light-color: $primaire-500 !default;
// $links-light-color-hover: $secondaire-500 !default;
// $links-white-color-hover: $primaire-500 !default;

// Buttons
// $focus-visible: $secondaire-500 !default;

// Animations

// $animations-transition-delay: 1s !default;
// $animations-transform-scale: 1.1 !default;

// Buttons
// $buttons-color: $secondaire-500 !default;
// $buttons-bgColor: $primaire-500 !default;
// $buttons-radius: $radius !default;
// $buttons-border: math.div($border-size, 3) $border-style $secondaire-500 !default;
// $buttons-font-family: $font-1 !default;
// $buttons-font-size: 1rem !default;
// $buttons-padding-x: math.div($padding-base, 2)  !default;
// $buttons-padding-y: math.div($padding-base, 1.5) !default;
// $buttons-hover-color: $secondaire-100 !default;
// $buttons-hover-border-color: $primaire-500 !default;

// Accordions
// $accordion-border-color: $secondaire-500 !default;
// $accordion-padding: 2rem !default;

// Pagination
// $pagination-color: $secondaire-500 !default;
// $pagination-bgColor: transparent !default;
// $pagination-color-active: white !default;
// $pagination-bgColor-active: $primaire-500 !default;
// $pagination-width: 2rem !default;
// $pagination-height: 2rem !default;
// $pagination-margin-x: 1rem !default;
// $pagination-margin-y: 0 !default;
// $pagination-radius: 0 !default;
// $pagination-opacity: 1 !default;

// Pagination slider
// $pagination-slider-bgColor: $primaire-500 !default;
// $pagination-slider-padding-x: .3rem !default;
// $pagination-slider-padding-y: .3rem !default;

// Slider
// $slider-margin-y: 2rem !default;

// List
// $list-bullet-size: .4rem !default;
// $list-bullet-offset: addition($list-bullet-size, .8rem) !default;
// $list-bullet-width: $list-bullet-size !default;
// $list-bullet-height: $list-bullet-size !default;
// $list-bullet-bgColor: $secondaire-500 !default;
// $list-bullet-radius: $radius !default;

// Footer
// $footer-color: white !default;
// $footer-bgColor: $secondaire-500 !default;
// $footer-barre: 0.1rem solid $secondaire-500 !default;
// $footer-copyright-padding: 2rem !default;
// $footer-padding: 1rem !default;
// $footer-titles-marge-bottom: 1rem !default;
// $footer-link-color: $secondaire-500 !default;
// $footer-link-color-hover: $secondaire-100 !default;
// $footer-logo-max-height: 4rem !default;
// $footer-logo-min-width: 40px !default;

// // Divider
// $divider-color: $secondaire-500 !default;
// $divider-height: .1rem !default;
// $divider-border: none !default;
// $divider-marge-y: 2rem !default;

// // Overlay
// $overlay-color: white !default;

// // Breadcrumbs
// $breadcrumbs-color: $primaire-500 !default;
// $breadcrumbs-color-hover: $secondaire-500 !default;
// $breadcrumbs-marge-y: 1rem !default;
// $breadcrumbs-marge-x: 0 !default;
// $breadcrumbs-position: center !default;
// $breadcrumbs-text-transform: initial !default;
// $breadcrumbs-divider-color: $primaire-500 !default;
// $breadcrumbs-active: $secondaire-500 !default;

// // Headers
// $headers-border-bottom: .2rem solid $secondaire-500 !default;
// $headers-padding-y: 4rem !default;
// $headers-padding-y-lg: 4rem !default;

// // Features
// $features-padding-y: 1rem !default;
// $features-padding-x: 0 !default;

// // Pricing
// $pricing-detail-width: 25rem !default;
// $pricing-detail-font-size: .9rem !default;
// $pricing-dotted-line-color: $primaire-500 !default;

// // Aside
// $aside-fixed-width: 400px !default;




