/* Error page
   ========================================================================== */


.error{

    [class*="button__border-"] .button__content{
        color: white;
    }

}

#page-02{
    footer,
    #topButton{
        display: none;
        visibility: hidden;
    }
}

.status-code{
    font-family: var(--font-family2);
    color: var(--color1-500);
    font-size: 8rem;
    font-weight: 600;
}