.textImage{
    align-items: stretch;

    .col.content {
        max-width: 704px;
        margin-right: 0;
        padding: 3rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media screen and (min-width: 768px){ 
            padding: 5rem 0;
        }

        &.reverse{
            order: 1;
            margin-left: 0;
            margin-right: auto;
        }
    }

    .col{


        @media screen and (max-width: 767px){ 
            .px-3{
                padding: 0 2rem;
            }
        }

        
        @media screen and (min-width: 640px){ 
            min-width: 350px;
        }

        &.image {
            
            @media screen and (min-width: 766px){ 
                max-width: 50%;
            }
    
            & > *:first-child{
                height: 100%;
            }
    
            & [class*=" images--max"]{
                max-height: initial;
            }
        }
    }
    
}