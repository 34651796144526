/* Buttons
   ========================================================================== */

.button{
    display: block;
    max-width: max-content;
    position: relative;
    @extend .px-2;
    @extend .py-1;
    margin-top: 0.5rem;
    margin-bottom: .5rem;   
    color: currentColor;
    text-transform: uppercase;
    font-family: $font-2;
    font-size: 1.2em;
    overflow: hidden;
    border-radius: 0;
    -webkit-appearance: none;
    

    &:before{
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 150%;
        left: -10px;
        top: 0;
        transform: translateX(-100%);
    }

    &:hover{
        &:before{
            transition: all 0.4s ease-in-out;
            transform: translateX(0%);
            left: 0px;
        }
    }

    &--secondary,
    &--primary{
        @extend .button; 
    }

    &--secondary{
        border: 0.25rem solid $secondaire-500;
        background-color: $secondaire-500;

        @media screen and (min-width: 1024px){ 
            &:before{
                background: $primaire-500;
            }
        }
    }

    &--primary{
        border: 0.25rem solid $primaire-500;
        background-color: $primaire-500;

        @media screen and (min-width: 1024px){ 
            &:before{
                background: $secondaire-500;
            }
        }
    }

    &__content{
        position: relative;
        z-index: 5;
        background: transparent;
        color: white;
    }

    &__border{
        

        &--secondary{
            @extend .button;
            border: .25rem solid $secondaire-500;
        }
        &--primary{
            @extend .button;
            border: .25rem solid $primaire-500;
        }

        .button__content{
            @extend .text-color2-500;
        }
    }

    &[class*="__border"]{
        
        .button__content{
            @extend .text-color2-500;
            
        }
    }

    &__group{
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
    }
}

.align-center,
.align-right{
    .button{
        margin: 0;
    }
}

.align-center{
    .button__group{
        justify-content: center;
    }
}

.align-right{
    .button__group{
        justify-content: flex-end;
    }
}