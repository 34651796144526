/* Footer
   ========================================================================== */

.footer{
    @extend .radius--right-top-big;

    .navigation{
        display: flex;
        justify-content: space-between;
        gap: 2rem;
        flex-wrap: wrap;
    }

    .col{
        flex: 1 1 0%;
        width: 100%;
        min-width: 250px;
    }

    &

    .link.link--light{
        background-image: linear-gradient(to right, var(--color1-500), var(--color1-500) 50%, currentColor 50%) !important;
        color: white !important
    }


    @media screen and (max-width: 1023px){ 
        img{
            margin: auto;
        }
    }

    .logo img{
        max-width: 270px;
    }

    .made{
        display: flex;
        align-items: center;
        gap: .2rem;
    }

    svg{
        fill: var(--color1-500);
    }
    
}

.footer.bg-color2-500{
    p{
        color: var(--color1-500);
        margin: 0;
        padding: 0;
    }

    .copyright{
        gap: 1rem;
    }
    
}