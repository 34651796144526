/* socialBarre
   ========================================================================== */

.socialBarre{
    &__icones{
        display: flex;
        gap: 0.5rem;
        align-items: center
        
    }
}