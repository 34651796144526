/* Lists
   ========================================================================== */

ul, ol{
    margin: 0;
    padding: 0;
    @extend .wrapper;
}

li{
    list-style-image: url(./../images/svg/puce.svg);
    padding-left: .5rem;
    margin-top: .7rem;
    margin-bottom: .7rem;
    

    &.list-none{
        list-style: none;
    }

}