/* Categories
   ========================================================================== */

.categories{

    display: flex;
    gap: .5rem;
    align-items: center;

    .categorie{
        color: white;
        background: $tertiaire-500;
        padding: .8rem;
        font-family: $font-2;
        text-transform: capitalize;
    }
}