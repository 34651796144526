/* Spacing
   ========================================================================== */


$directions: 
    'r' 'right',
    'l' 'left', 
    't' 'top', 
    'b' 'bottom' ;
$directionsMultiple: 
    'y' 'top' 'bottom',
    'x' 'right' 'left';



@for $i from -50 through 100 {
    .m-#{$i}{
        margin: #{$i}rem;
    }
    @each $direction, $value1 , $value2 in $directionsMultiple {
        .m#{$direction}-#{$i}{
            margin-#{$value1}: #{$i}rem;
            margin-#{$value2}: #{$i}rem;
        }
    }
    @each $direction, $value in $directions{
        .m#{$direction}-#{$i}{
            margin-#{$value}: #{$i}rem;
        }
    }
}

.mx-auto{
    margin-left: auto;
    margin-right: auto;
}

.ml-auto{
    margin-left: auto;
}

.mr-auto{
    margin-left: auto;
}

@for $i from 0 through 100 {
    .p-#{$i}{
        padding: #{$i}rem;
    }
    @each $direction, $value1 , $value2 in $directionsMultiple {
        .p#{$direction}-#{$i}{
            padding-#{$value1}: #{$i}rem;
            padding-#{$value2}: #{$i}rem;
        }
    }
    @each $direction, $value in $directions{
        .p#{$direction}-#{$i}{
            padding-#{$value}: #{$i}rem;
        }
    }
}

@for $i from 0 through 50 {
    .gap-#{$i}{
        gap: #{$i}rem;
    }
    @each $direction, $value1 , $value2 in $directionsMultiple {
        .gap-#{$direction}-#{$i}{
            gap-#{$value1}: #{$i}rem;
            gap-#{$value2}: #{$i}rem;
        }
    }
}