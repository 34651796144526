/* Menu
   ========================================================================== */
.menu{
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10000;
    // background: white;
    background: $primaire-500;
    transition: all .2s ease-in;

    // @media screen and (min-width: 768px){ 
    //     background: transparent;
    // }

    // @media screen and (max-width: 767px){ 
    //     position: fixed;
    // }

    &__bgColor{
        background: white;

        .menu__item .link{
            color: $tertiaire-500;
        }
    }

    &__wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: .3rem;
        width: auto;
        max-width: initial;
        padding: .8rem;

        @media screen and (min-width: 1024px){ 
            @include wrapper
        }
    }

    &__main{
        position: absolute;
        left: 0;
        right: 0;
        background: white;
        transition: height 1s ease-in-out;
        height: 0;
        width: 100%;
        

        &--open{
            height: 100vh;
        }

        @media screen and (max-width: 767px){ 
            overflow: hidden 
        }

        @media screen and (min-width: 768px){ 
            display: flex;
            position: relative;
            transform: translateY(0);
            background: transparent;
            height: auto;
            top: initial !important;
        }
    }

    @import "./logo";
    @import "./list";
    @import "./burger";

    .icon{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        gap: .4rem;

        // &.icon-mail{
        //     font-size: .8rem;
        // }

        span{
            font-size: 1rem;

            @media screen and (min-width: 768px){ 
                display: none;
            }
        }
    }

}