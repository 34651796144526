&__checkbox{

    input{
        display: none;
        position: absolute;
        top: -1000px;

        // &:checked ~ .checkbox__label::before{
        //     background: $form-color-1;
        // }

        &:checked ~ .checkbox__label::after{
            content: "\f10a";
            font-family: 'icons';
            color: $tertiaire-500;
            position: absolute;
            top: 25px;
            left: .75rem;
            transform: translate(-50%, -50%);
            font-size: 1rem;
        }
    }

    label{
        position: relative;
        text-transform: initial;
        padding: 1em;
        padding-left: $form-checkbox-size + $form-checkbox-marge;
        
        
        &::before{
            content: '';
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid $primaire-500;
            width: $form-checkbox-size;
            height: $form-checkbox-size;
            position: absolute;
            left: 0;
            @extend .radius--right-bottom-min;
            @extend .radius--left-top-min;
        }
    }
}