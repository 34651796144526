
/* Radius
   ========================================================================== */

$params-size: ( '' : 1.5, '-med' : 3, '-big' : 7, '-Xbig' : 14, '-min' : 0.6 ) ;
$params-y: 'top', 'bottom' ;
$params-x: 'left', 'right' ;

@each $name, $size in $params-size{
    @each $y in $params-y{
        @each $x in $params-x{
            .radius{

                @if($size >= 7 ){
                    $min: math.div($size , 2.4);

                    &--#{$x}-#{$y}#{$name}{
                        border-#{$y}-#{$x}-radius: clamp( #{$min}rem ,20vw, #{$size}rem);
                    }
                    
                }@else{
                    &--#{$x}-#{$y}#{$name}{
                        border-#{$y}-#{$x}-radius: #{$size}rem ;
                    }
                }                
            }
        }
    }
}