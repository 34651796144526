/* Video
   ========================================================================== */

.video.video-js {
    width: 90%;
    max-height: 550px;
    height: 100%;
    aspect-ratio: 1;
    margin: auto;
    display: flex;

    .vjs-big-play-button {
        font-size:3em;
        width:2em;
        height:2em;
        position:absolute;
        top:50%;
        left:50%;
        background-color:white;
        border-radius:50%;
        transition:all .4s;
        border: none;
        display: block;
        transform: translate(20%);
    }

    .vjs-icon-placeholder{
        display: flex;
    }

    .vjs-big-play-button .vjs-icon-placeholder::before{
        position: relative;
        color: $secondaire-500;
    }

    &:hover{
        .vjs-big-play-button .vjs-icon-placeholder::before{
            color: $primaire-500;
        }
    }

    @media screen and (max-width: 639px){ 
        width: 100%;
    }
}


.vjs-playing .vjs-big-play-button{
    display: none !important;
}


.video--bg{
    width: 100vw;
    position: absolute;
    overflow: hidden;
    height: 100%;

    iframe,
    .video-js {
        position: absolute !important;
        height: 100% !important;
        transform: translate(-50%, -50%) scale(1.2);
        top: 50% !important;
        left: 50% !important;
        pointer-events: none;
        aspect-ratio: 16/9;
        width: initial;

        @media (min-aspect-ratio: 15 / 9) {
            width: 100% !important;
            height: auto !important;
        }
        
        .vjs-big-play-button{
            display: none;
        }
    }

    .video__content{
        width: 100%;
        min-height: 600px;
        position: relative;
        color: #FFFFFF;
        padding-top: 5rem;
        padding-bottom: 5rem;
        display: flex;
        flex-direction: column;

    }
}