/* Topbarre
   ========================================================================== */

.topBarre{

    @extend .py-1;
    @extend .bg-color1-500;
    @extend .text-color2-500;
    @extend .font-2;
    display: none;

    @media screen and (min-width: 640px){ 
        display: block;
    }

    .wrapper{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: .5rem 0;

        & > *  {
            display: flex;
            gap: 1rem;
            align-items: center
        }
    
    }

    .icofont{
        @extend .text-white;
    }

    .link{
        color: white;

        &:hover{
            color: $secondaire-500;
        }
    }

    &__contact {
        flex-wrap: wrap;

        & > * {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            flex-wrap: wrap;
        }

        .link{
            color: $secondaire-500;
        }
    }
}