/* Features
   ========================================================================== */
.features{
    .card__image{
        height: 300px;

    }

    img:hover{
        
        @media screen and (min-width: 1024px){ 
            transform: scale(1.2);
            transition: all .5s ease-in-out;
        }
    }

    .grille{
        display: grid;
        padding: 0 0rem;
        gap: 0 5rem ;


        @media screen and (min-width: 768px){ 
            grid-template-columns: repeat(2, minmax(0, 1fr));
            padding: 0 5rem;
        }

        @media screen and (min-width: 1024px){ 
            padding: 0 10rem;
        }

        &--4{
            @extend .grille;
            @media screen and (min-width: 768px){ 
                grid-template-columns: repeat(2, minmax(0, 1fr));
                padding: 0rem;
            }

            @media screen and (min-width: 1280px){ 
                grid-template-columns: repeat(4, minmax(0, 1fr));
                padding: 0rem;
            }
        }
    }
}