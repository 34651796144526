/* Forms
   ========================================================================== */


$form-color-1: $primaire-500;
$form-color-2: white;
$form-color-3: $secondaire-500;
$form-fonts-icone: toto;
$form-outline: 2px solid $form-color-1;
$form-checkbox-size: 1.2rem;
$form-checkbox-marge: .7rem;

input[type=text], input[type=button] {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
}

.form{
    font-family: $font-2;

    &__group{
        display: flex;
        flex-direction: column;
        margin: 1.5em 0;

        &--danger .form__control{
            border-bottom: .4rem solid #BA0808;
        }

        &--danger .form__checkbox label::before {
            border-color: #BA0808;
        }

        &--success .form__control{
            border-bottom: .4rem solid $primaire-500;
        }
    }

    &__label{
        text-transform: uppercase;
        margin: .5em 0;
    }
    
    &__control{
        padding: 1em;
        background: transparent;
        border: none;
        border-bottom: 2px solid $form-color-3;
        color: $form-color-3;
        outline: 1px solid transparent;
        border-radius: 0;

        &:focus-visible,
        &:focus-within{
            border: none;
            outline: $form-outline;
            background: $primaire-500;
            color: $form-color-2;
        }

        &::placeholder{
            color: var(--color2-500);
        }
    }

    &__textarea{
        resize: none;
        min-height: 150px;
    }

    &__message{
        color: #BA0808;
        padding: 1em 0;
        font-family: $font-1;
    }

    // &__submit{
    //     color: white;
    //     background: none;
    //     border: none;
    // }


    @import 'forms__checkbox';

}









