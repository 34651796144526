/* Colors
   ========================================================================== */

@each $mapColors, $nameMapColors in $colorsVariantes {
    @each $nameColor, $color in $nameMapColors {

        .text-#{$mapColors}-#{$nameColor}{
            color: #{$color}
        }

        .bg-#{$mapColors}-#{$nameColor}{
            background-color: #{$color};
        }
    }
}

.text-white{
    color: white
}
.bg-white{
    background-color: white
}

.bg-default,
.bg-color1-500,
.bg-color2-500{

    // p{
    //     margin: 0;
    //     padding-bottom: .5rem
    // }

    a:not(.button){
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: 200% 100%;
        background-position: 100%;
        text-decoration: none;

        &:hover{
            transition: all 0.4s ease-in-out;
            background-position: 0%;
        }
    }

}

.bg-default{
    h1,h2,h3,h4,h5,h6,a{
        @extend .text-color1-500;
    }

    a:not(.button){
        background-image: linear-gradient(to right, var(--color2-700), var(--color2-700) 50%, currentColor 50%);
    }
}

.bg-color1-500{

    h1,h2,h3,h4,h5,h6,a{
        @extend .text-white;
    }

    a:not(.button){
        background-image: linear-gradient(to right, var(--color2-700), var(--color2-700) 50%, currentColor 50%);

        &:hover{
            @extend .text-color2-500;
            background-image: linear-gradient(to right, var(--color1-700), var(--color1-700) 50%, currentColor 50%);
        }
    }

    li{
        list-style-image: url(./../images/svg/puce-grey.svg);
    }

}

.bg-color2-500{

    h1,h2,h3,h4,h5,h6,a{
        @extend .text-color1-500;
    }

    p{
        @extend .text-color1-100;
    }

    a:not(.button){
        @extend .link;
        background-image: linear-gradient(to right, var(--color2-700), var(--color2-700) 50%, currentColor 50%);
    }

    li{
        @extend .text-white;
    }

    .thead .th{
        color: white;
    }

}

.overlay__content{
    a:not(.button){
        @extend .link;
        background-image: linear-gradient(to right, var(--color2-700), var(--color2-700) 50%, currentColor 50%);
    }
}
