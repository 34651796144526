/* card
   ========================================================================== */


.card{
    overflow: hidden;
    font-size: .9rem;

    &__content{
        padding: 1.5rem;
        display: grid;
        gap: .5rem;
        
    }

    &__image{
        height: 280px;
        display: block;
        overflow: hidden;
    }

    h3 {
        font-size: 1.3rem;
        // @extend .mb-1;
    }

    .date p {
        margin-top: 0.5rem;
    }

    &.article .card__content{
        display: block;
    }
}

