
@import './root';
@import './reset';
@import './typographie';
@import './icons';
@import './colors';
@import './lists';

::selection {
    background-color: $secondaire-500;
    color: white;
}

html{
    overflow-x: hidden;
    font-weight: 200;
    font-size: $font-base;
    font-family: $font-2;
    scroll-behavior: smooth;
    color: $secondaire-500; 
}

p{
    margin: 0;
    padding-bottom: .5rem;
    font-family: var(--font-family2);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4;
}



.wrapper{
    @include wrapper;
}

.wrapper-2{
    @include wrapper-2;
}

:focus-visible {
    outline-color: $secondaire-500;
    outline-style: solid;
    outline-width: 1px;
}

.relative{
    position: relative;
}

.absolute{
    position: absolute;

    &--full{
        @extend .absolute;
        left: 0;
        right: 0;
    }
}

.hide{
    display: none;
    visibility: hidden;
}

.show{
    display: flex;
    visibility: visible;
}

.overflow-hidden{
    overflow: hidden;
    width: 100%;
}

.block{
    display: block;
}

.w-full{
    width: 100%;
}

.white-space-nowrap{
    white-space: nowrap;
}

// body{

//     position: relative;
//     &:before{
//         content: '';
//         display: block;
//         width: 50px;
//         height: 50px;
//         background: red;

//         position: absolute;
//         left: 0;
//         right: 0;
//         top: 0;
//         bottom: 0;
//     }
// }


html,
body {
   height: 100%;
}
body {
   display: flex;
   flex-direction: column;
}
.content {
   flex: 1 0 auto;
   margin-top: 75px;
}
.footer {
   flex-shrink: 0;
}

