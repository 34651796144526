/* Images
   ========================================================================== */

picture{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.images{

    &--cover{
        object-fit: cover;
    }

    &--contain{
        object-fit: contain;
    }

    &--full{
        @extend .images--cover;
        width: 100%;
        height: 100%;
    }

    &--H-full{
        @extend .images--cover;
        width: 100%;
    }

    &--contain{
        @extend .images--contain;
        width: 100%;
        height: auto;
    }

    &--max-800{
        max-height: 800px;
    }

    &--max-600{
        max-height: 600px;
    }

    &--h-300{
        height: 300px;
    }

    &--h-225{
        height: 225px;
    }

    &--h-125{
        height: 125px;
    }
}